<template>
   <!-- 	 
		-La magie
		-
		-
		-
		-
		-
		-
		-
		-
		-	  			 
		 -->

   <div class="">
      <b-card no-body class="py-2">
         <div class="px-2">
            <!-- Table Top -->
            <b-row>
               <!-- Per Page -->
               <b-col
                  cols="12"
                  md="6"
                  class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
               >
                  <label>Entrées</label>
                  <v-select
                     v-model="state.perPageDevis"
                     :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                     :options="perPageOptions"
                     :clearable="false"
                     class="per-page-selector d-inline-block ml-50 mr-1"
                  />
                  <b-button variant="primary" :to="{ name: 'DevisAdd' }">
                     <div class="flex gap-2">
                        <feather-icon icon="PlusIcon" class="mx-auto" />
                        <span> Nouveau devis</span>
                     </div>
                  </b-button>

                  <QExportData :xtype="'devis'" :data="xExportData__Devis" />
               </b-col>

               <!-- Search -->
               <b-col cols="12" md="6">
                  <div class="d-flex align-items-center justify-content-end">
                     <b-input-group class="input-group-merge">
                        <b-input-group-prepend is-text>
                           <feather-icon icon="SearchIcon" />
                        </b-input-group-prepend>
                        <b-form-input
                           v-model="state.filtreDevis"
                           class="d-inline-block"
                           placeholder="Rechercher par : client, montant, numero de facture"
                        />
                     </b-input-group>
                  </div>
               </b-col>
            </b-row>
         </div>
         <b-card-text>
            <!-- Loader -->
            <q-loader-table
               :success="state.success__devis"
               :empty="state.empty__devis"
               :warring="state.warring"
            />

            <b-table
               responsive
               primary-key="line"
               hover
               show-empty
               :items="devisLists__data"
               :currentPage="state.currentPageDevis"
               :perPage="state.perPage"
               :fields="tableColumnsDevis"
               :filter="state.filterDevis"
               v-if="state.success__devis === true"
            >
               <template #head(invoiceStatus)>
                  <feather-icon icon="TrendingUpIcon" class="mx-auto" />
               </template>

               <template #cell(devisStatus)="data">
                  <b-badge variant="primary">
                     {{
                        data.item.status_devis === null
                           ? 'En attente'
                           : data.item.status_devis
                     }}
                  </b-badge>
               </template>

               <!-- Column: Client -->
               <template #cell(client)="data">
                  <b-media vertical-align="center">
                     <!-- <template #aside>
                  <b-avatar
                    size="32"
                    :src="
                      data.item.client === null
                        ? ''
                        : data.item.client.profile_photo_url
                    "
                  />
                </template> -->
                     <span
                        class="font-weight-bold text-nowrap d-flex align-content-center"
                     >
                        {{
                           data.item.client === null
                              ? 'none'
                              : data.item.client.nom | truncate
                        }}
                     </span>
                     <small class="text-muted">{{ data.item.email }}</small>
                  </b-media>
               </template>

               <!-- Column: Montant total -->
               <template #cell(total_ttc)="data">
                  <span class="text-nowrap text-info">
                     {{ formatter(data.item.total_ttc) }}
                  </span>
               </template>

               <!-- Column: Payé -->
               <template #cell(paye)="data">
                  <span class="text-nowrap text-success">
                     {{ formatter(data.item.amountPaid) }}
                  </span>
               </template>

               <!-- Column: Impayé -->
               <template #cell(impaye)="data">
                  <span class="text-nowrap text-danger font-weight-bold">
                     {{ formatter(data.item.amountToPaid) }}
                  </span>
                  <!-- <span
					v-if="Number(data.item.somme_paye) === data.item.total_ttc"
					class="text-nowrap"
				>
					<b-button variant="success" size="sm" pill>
						Réglé
					</b-button>
				</span> -->
               </template>

               <!-- Column: Issued Date -->
               <template #cell(created_at)="data">
                  <span class="text-nowrap">
                     {{ format_date(data.item.created_at) }}
                  </span>
               </template>

               <!-- Column: Actions -->
               <template #cell(actions)="data">
                  <div class="flex items-center text-nowrap py-1 ">
                     <div
                        v-b-modal.modal-transfert
                        v-if="data.item.transition === null"
                        @click="
                           changeDevisToInvoice(
                              data.item.id,
                              data.item.code,
                              'statut_facture'
                           )
                        "
                        class="flex items-center gap-1 text-white bg-orange-500 text-xs font-bold rounded-md px-[6px] py-[4px]  "
                        :id="`invoice-row-${data.item.id}-send-icon`"
                     >
                        <feather-icon
                           :id="`invoice-row-${data.item.id}-converte-icon`"
                           icon="ClipboardIcon"
                           size="12"
                           class=" cursor-pointer text-white"
                        />
                        <span>Convertir</span>
                     </div>

                     <div class="text-center mx-[8px] ">
                        <div
                           class="py-[1px] px-[8px] bg-purple-500 text-[10px] rounded text-white"
                           :id="`my-button-${data.item.id}`"
                        >
                           Status
                        </div>
                        <b-popover
                           :target="`my-button-${data.item.id}`"
                           custom-class="my-popover-class"
                        >
                           <template #title>
                              Statut des devis
                           </template>

                           <b-dropdown
                              class="absolute"
                              text="Types"
                              variant="outline-secondary"
                           >
                              <template #button-content>
                                 <span> Statuts </span>
                              </template>
                              <b-dropdown-item
                                 v-for="(statut, index) in statutDevis"
                                 :key="index"
                                 @click="
                                    changeDevisToInvoice(
                                       data.item.id,
                                       statut.label,
                                       'statut_devis'
                                    )
                                 "
                              >
                                 <div class="flex items-center">
                                    <feather-icon
                                       :icon="statut.icon"
                                       class="mr-50"
                                    />
                                    <span>{{ statut.label }}</span>
                                 </div>
                              </b-dropdown-item>
                           </b-dropdown>
                        </b-popover>
                     </div>

                     <feather-icon
                        v-if="data.item.transition !== null"
                        icon="CheckCircleIcon"
                        size="16"
                        class="text-primary"
                     />
                     <b-tooltip
                        title="convertir en facture"
                        :target="`invoice-row-${data.item.id}-converte-icon`"
                     />

                     <feather-icon
                        :id="`invoice-row-${data.item.id}-preview-icon`"
                        icon="EyeIcon"
                        size="16"
                        class="mx-1 cursor-pointer"
                        @click="infoTreatments(data.item.id, 'view')"
                     />

                     <b-tooltip
                        title="Prévisualiser la facture"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :target="`invoice-row-${data.item.id}-preview-icon`"
                     />

                     <!-- Dropdown -->
                     <b-dropdown
                        variant="link"
                        toggle-class="p-0"
                        no-caret
                        :right="$store.state.appConfig.isRTL"
                     >
                        <template #button-content>
                           <feather-icon
                              icon="MoreVerticalIcon"
                              size="16"
                              class="align-middle text-body"
                           />
                        </template>
                        <b-dropdown-item
                           @click="infoTreatments(data.item.id, 'edit')"
                        >
                           <div class="flex w-full items-center">
                              <feather-icon icon="Edit3Icon" />
                              <span class="align-middle ml-50">Modifier</span>
                           </div>
                        </b-dropdown-item>
                        <!-- <b-dropdown-item
									v-b-modal.modal-mail-sender
									@click="colletDataInvoiceToSendMail(data.item)"
								>
									<feather-icon icon="TrashIcon" />
									<span class="align-middle ml-50"> Envoyer</span>
								</b-dropdown-item> -->

                        <b-dropdown-item @click="invoiceDestroy(data.item.id)">
                           <div class="flex w-full items-center">
                              <feather-icon icon="TrashIcon" />
                              <span class="align-middle ml-50"> Supprimer</span>
                           </div>
                        </b-dropdown-item>
                     </b-dropdown>
                  </div>
               </template>
            </b-table>

            <!-- Paginator -->
            <div class="mx-2 mb-2">
               <b-row>
                  <b-col
                     cols="12"
                     sm="6"
                     class="d-flex align-items-center justify-content-center justify-content-sm-start"
                  >
                     <span class="text-muted"></span>
                  </b-col>
                  <!-- Pagination -->
                  <b-col
                     cols="12"
                     sm="6"
                     class="d-flex align-items-center justify-content-center justify-content-sm-end"
                  >
                     <b-pagination
                        v-model="state.currentPageDevis"
                        :total-rows="devisLists__data.length"
                        :per-page="state.perPage"
                        first-number
                        last-number
                        class="mb-0 mt-1 mt-sm-0"
                        prev-class="prev-item"
                        next-class="next-item"
                        align="right"
                     >
                        <template #prev-text>
                           <feather-icon icon="ChevronLeftIcon" size="18" />
                        </template>
                        <template #next-text>
                           <feather-icon icon="ChevronRightIcon" size="18" />
                        </template>
                     </b-pagination>
                  </b-col>
               </b-row>
            </div>
         </b-card-text>
      </b-card>
   </div>
</template>

<script>
import { heightTransition } from '@core/mixins/ui/transition';
import Ripple from 'vue-ripple-directive';
import {
   BRow,
   BCol,
   BCard,
   BCardBody,
   BButton,
   BModal,
   BForm,
   BFormGroup,
   BFormInput,
   BInputGroup,
   BInputGroupPrepend,
   BFormTextarea,
   VBToggle,
   VBModal,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import flatPickr from 'vue-flatpickr-component';
import store from '@/store';
import URL from '@/views/pages/request';
import axios from 'axios';
import { VueTelInput } from 'vue-tel-input';
import 'animate.css';
import { onMounted, ref, computed, reactive } from "@vue/composition-api"

export default {
   components: {
      BRow,
      BCol,
      BCard,
      BCardBody,
      BButton,
      BForm,
      BFormGroup,
      BFormInput,
      BInputGroup,
      BInputGroupPrepend,
      BFormTextarea,
      BModal,
      flatPickr,
      vSelect,
      VBModal,
      VueTelInput,
   },
   directives: {
      Ripple,
      'b-modal': VBModal,
      'b-toggle': VBToggle,
   },
   setup() {
      const state = reactive({
         success__devis: false,
         empty__devis: false,

         warring: false,
         filter: '',
         filterDevis: '',

         currentPageDevis: 1,

         perPage: 30,
         filter: '',
         invoiceData: {},
         perPageDevis: 30,
      });
      const see = ref(false);
      const statutDevis = [
         { label: 'En attente', icon: '', color: 'yellow-500' },
         { label: 'Refusé', icon: '', color: 'red-500' },
         { label: 'Effectuez', icon: '', color: 'red-500' },
         { label: 'Archivé', icon: '', color: 'red-500' },
      ];
      const perPageOptions = [30, 40, 50, 100, 150, 500];

      // Table Handlers
      const tableColumnsDevis = [
         { key: 'devisStatus', label: 'Statut' },
         { key: 'code', label: 'Libelle', sortable: true },
         { key: 'client', label: 'clients', sortable: true },
         { key: 'total_ttc', label: 'Montant Total' },
         { key: 'created_at', label: "Date d'Ajout" },
         { key: 'actions' },
      ];

      const devisLists__data = ref([]);
      const loadingIsConvertToInvoice = ref(false);

      onMounted(async () => {
         document.title = 'Facture - Ediqia';

         await getInvoiceFacture();
      });

      const getInvoiceFacture = async () => {
         await axios
            .get(URL.FACTURE_LIST)
            .then(async ({ data }) => {
               const devisLists__data_to_invoice = data.facture.filter(
                  (item) => {
                     return item.transition === 'devis_to_invoice';
                  }
               );

               see.value = true;

               // Devis

               const devisLists__data_ = data.facture.filter((item) => {
                  return item.status === 'devis';
               });

               // console.log(devisLists__data_);

               const devisLists__data = devisLists__data_.concat(
                  devisLists__data_to_invoice
               );

               devisLists__data.reverse();
               root.$store.commit('qDevis/LIST_DATA_DEVIS', devisLists__data, {
                  root: true,
               });

               // CALL FUNCTION TO EXPORT DATA
               xExportData__Devis.value = QExportDataClass.transformFormatDataToExport(
                  DevisProvider(devisLists__data)
               );
            })
            .catch((error) => {
               state.warring = true;
               console.log(error);
            });
      };

      const getInvoiceLists = computed(() => {
         if (see.value === true) {
            //
            devisLists__data.value = root.$store.state.qDevis.dataDevis;
            devisLists__data.value.length > 0
               ? ((state.success__devis = true), (state.empty__devis = false))
               : ((state.success__devis = false), (state.empty__devis = true));
         }

         return {
            data: root.$store.state.qInvoice.dataInvoice,
            count: root.$store.state.qInvoice.countDataInvoice,
         };
      });

      const changeDevisToInvoice = async (id, code, ops) => {
         root
            .$swal({
               title:
                  ops === 'statut_devis'
                     ? `Êtes vous sûr de vouloir mettre ${code} ce devis`
                     : `Êtes vous sûr de vouloir convertir le devis ${code} en facture `,

               text: '',

               icon: 'warning',

               showCancelButton: true,

               confirmButtonText: 'Oui',

               customClass: {
                  confirmButton: 'btn btn-primary',

                  cancelButton: 'btn btn-outline-danger ml-1',
               },

               buttonsStyling: false,
            })
            .then(async ({ isConfirmed }) => {
               if (isConfirmed) {
                  loadingIsConvertToInvoice.value = false;

                  try {
                     const { data } = await axios.post(URL.DEVIS_TO_FACTURE, {
                        id: id,
                        transition:
                           ops === 'statut_devis' ? null : 'devis_to_invoice',
                        etat: ops === 'statut_devis' ? 'validate' : 'validate',
                        status_devis:
                           ops === 'statut_devis' ? code : 'En cours',
                     });

                     if (data) {
                        loadingIsConvertToInvoice.value = false;
                        // Devis
                        const devisLists__data =
                           root.$store.state.qDevis.dataDevis;
                        devisLists__data.forEach((invc) => {
                           if (invc.id === id) {
                              if (ops === 'statut_devis') {
                                 invc.status_devis = code;
                              } else {
                                 invc.status = 'facture';
                                 invc.transition = 'devis_to_invoice';
                              }
                           }
                        });
                        toast_sucess(
                           root,
                           'success',
                           'top-right',
                           'Votre devis -> Facture convertir avec succés'
                        );

                        root.$store.commit(
                           'qDevis/LIST_DATA_DEVIS',
                           devisLists__data,
                           {
                              root: true,
                           }
                        );
                     }
                  } catch (error) {
                     toast_error(
                        root,
                        'danger',
                        'top-right',
                        'Oups, un erreur est survenu au serveur !'
                     );
                     loadingIsConvertToInvoice.value = false;
                     console.log(error);
                  }
               }
            });
      };

      return {
         state,
         statutDevis,
         perPageOptions,
         devisLists__data,
         loadingIsConvertToInvoice,
		 tableColumnsDevis
      };
   },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
